<template>
  <v-card outlined>
    <div class="d-flex justify-space-between">
      <div class="d-flex align-center px-2 py-2">
        <v-icon class="handle mr-1"> $mdi-drag </v-icon>
        <span class="font-weight-medium">Criteria {{ index + 1 }}</span>
      </div>

      <v-btn
        text
        small
        outlined
        class="text-none font-weight-regular mt-2 mr-2"
        @click.stop="$emit('delete')"
      >
        <v-icon left> $mdi-delete-outline </v-icon>
        Delete
      </v-btn>
    </div>

    <div class="ap-dark-gray--text px-4 pb-2 text-caption">
      <template v-for="(condition, conditionIndex) in conditions">
        <div v-if="condition.fields.length > 0" :key="conditionIndex">
          {{ condition.label }} on
          <span
            v-for="(field, fieldIndex) in condition.fields"
            :key="field.field"
          >
            <b>{{ fieldsObj[field.field] }}</b>
            <span v-if="fieldIndex < condition.fields.length - 2">, </span>
            <span v-else-if="fieldIndex < condition.fields.length - 1">
              and
            </span>
          </span>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MatchingCriteriaListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState('business-rules', {
      rulesMetadata: (state) => state.rulesMetadata,
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
    }),
    conditions() {
      return [
        {
          label: 'Exact Match',
          fields: this.item.conditions.filter((el) => el.algo === 'exact'),
        },
        {
          label: 'Starts With',
          fields: this.item.conditions.filter((el) => el.algo === 'startswith'),
        },
        {
          label: 'Fuzzy Match',
          fields: this.item.conditions.filter((el) => el.algo === 'fuzzy'),
        },
      ]
    },
    fieldsObj() {
      const fields =
        this.rulesMetadata?.MatchCriteria?.[this.multiMapRule.primaryEntity]
          ?.entities[0].children

      const fieldsObj = {}
      fields.forEach((field) => {
        fieldsObj[field.name] = field.label
      })

      return fieldsObj
    },
  },
}
</script>
