<template>
  <div class="criteria-configuration px-5 py-3 ap-light-grey">
    <div class="d-flex align-center">
      <div>
        <v-select
          label="Field"
          :value="value.field"
          :rules="fieldRules"
          hide-details
          outlined
          dense
          required
          :items="fields[0].children"
          item-value="name"
          item-text="label"
          append-icon="$mdi-chevron-down"
          color="accent"
          @change="onValueChanged('field', $event)"
        />
      </div>

      <span class="mx-4">is</span>

      <v-btn-toggle :value="value.algo" group tile class="criteria-alghoritm">
        <v-btn
          v-for="operator in operators"
          :key="operator.name"
          class="text-none font-weight-regular"
          :value="operator.name"
          outlined
          height="40"
          color="ap-dark-gray"
          :disabled="!operator.isEnabled"
          @click="selectOperator(operator)"
        >
          {{ operator.label }}
        </v-btn>
      </v-btn-toggle>

      <v-btn
        v-if="isDeleteVisible"
        icon
        class="ml-auto"
        @click="$emit('delete')"
      >
        <v-icon color="ap-gray"> $mdi-delete </v-icon>
      </v-btn>
    </div>

    <div v-if="value && operatorSelected" class="d-flex flex-column">
      <v-row no-gutters class="d-flex align-center mt-3">
        <v-btn icon small @click="detailsVisible = !detailsVisible">
          <v-icon
            color="ap-dark-gray"
            :class="{ 'section-hidden': !detailsVisible }"
          >
            $mdi-menu-down
          </v-icon>
        </v-btn>
        <span> Ignore: </span>
        <v-divider class="ml-2 ap-gray-base" />
      </v-row>

      <v-row v-if="detailsVisible" no-gutters class="d-flex align-start mt-2">
        <v-col
          v-for="option in selectedOperatorOptionsSorted"
          :key="option.name"
          cols="4"
          class="d-flex align-center px-4 pb-4"
        >
          <!-- Boolean -->
          <div v-if="option.type === 'boolean'" class="d-flex">
            <v-simple-checkbox
              :value="value[option.name]"
              :ripple="false"
              color="ap-blue"
              @input="onValueChanged(option.name, $event)"
            />
            <span class="ap-black--text">
              {{ option.label }}
            </span>
          </div>

          <!-- Number -->
          <div
            v-else-if="option.type === 'integer' || option.type === 'float'"
            class="d-flex"
            style="width: 100%"
          >
            <v-simple-checkbox
              v-model="expandedOptions[option.name]"
              :ripple="false"
              color="ap-blue"
            />
            <span v-if="!expandedOptions[option.name]" class="ap-black--text">
              {{ option.label }}
            </span>
            <v-text-field
              v-if="expandedOptions[option.name]"
              :value="value[option.name]"
              :label="option.label"
              :rules="option.type === 'float' ? floatRules : integerRules"
              type="number"
              hide-details
              dense
              outlined
              width="150"
              :min="option.min"
              :max="option.max"
              :step="option.type === 'float' ? '0.01' : '1'"
              color="accent"
              @change="onValueChanged(option.name, parseFloat($event))"
            />
          </div>

          <!-- Words List -->
          <div v-else-if="option.type === 'List'">
            <div class="d-flex">
              <v-simple-checkbox
                v-model="expandedOptions[option.name]"
                :ripple="false"
                color="ap-blue"
              />
              <span v-if="!expandedOptions[option.name]" class="ap-black--text">
                {{ option.label }}
              </span>
              <v-text-field
                v-else-if="expandedOptions[option.name]"
                v-model="wordToIgnore"
                hide-details
                dense
                outlined
                color="accent"
                :label="option.label"
                append-icon="$mdi-plus"
                @click:append="addWordToIgnore(option.name)"
                @keyup="addWordToIgnore(option.name, $event)"
              />
            </div>
            <div v-if="value[option.name]" class="mt-3">
              <v-chip
                v-for="(word, idx) in value[option.name]"
                :key="idx"
                class="mr-2 mb-1"
                close
                small
                close-icon="$mdi-close"
                @click:close="removeWordFromList(option.name, idx)"
              >
                {{ word }}
              </v-chip>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    operators: {
      type: Array,
      required: true,
    },
    isDeleteVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedOptions: {},
      isValid: false,
      detailsVisible: true,
      wordToIgnore: '',
      operatorSelected: null,
      fieldRules: [(v) => !!v || ''],
      floatRules: [(v) => (!!v && Number(v) > 0) || ''],
      integerRules: [
        (v) => (!!v && Number(v) > 0 && Number.isInteger(Number(v))) || '',
      ],
    }
  },
  computed: {
    selectedOperatorOptionsSorted() {
      const options = [...this.operatorSelected.options]

      return options.sort((option) => (option.type === 'boolean' ? -1 : 0))
    },
  },
  async created() {
    this.selectItemOperator()
    this.expandFilledElements()
  },
  methods: {
    expandFilledElements() {
      this.operatorSelected.options.forEach((el) => {
        if (
          ['integer', 'float', 'List'].includes(el.type) &&
          this.value[el.name] !== undefined
        ) {
          Vue.set(this.expandedOptions, el.name, true)
        }
      })
    },
    selectItemOperator() {
      let operatorToSelect = null

      if (this.value.algo) {
        operatorToSelect = this.operators.find(
          (el) => el.name === this.value.algo
        )
      } else {
        operatorToSelect = this.operators.find((el) => el.isEnabled)
      }

      this.selectOperator(operatorToSelect)
    },
    onValueChanged(prop, value) {
      this.$emit('input', {
        ...this.value,
        [prop]: value,
      })
    },
    selectOperator(operator) {
      this.operatorSelected = operator
      this.onValueChanged('algo', operator.name)
    },
    addWordToIgnore(fieldName, event) {
      if (this.wordToIgnore === '' || (event && event.keyCode !== 13)) return

      const wordsSelected = Array.isArray(this.value[fieldName])
        ? this.value[fieldName]
        : []

      this.onValueChanged(fieldName, [...wordsSelected, this.wordToIgnore])

      this.wordToIgnore = ''
    },
    removeWordFromList(fieldName, index) {
      const words = [...this.value[fieldName]]
      words.splice(index, 1)
      this.onValueChanged(fieldName, words)
    },
  },
}
</script>

<style lang="scss" scoped>
.criteria-configuration {
  border-radius: 5px;

  ::v-deep .v-btn-toggle {
    .v-btn {
      &.v-btn--outlined {
        border-width: 1px;
        border-radius: 6px;
        font-size: 13px;
        border-color: var(--v-ap-dark-gray-base);
        height: 42px;
      }

      &.v-btn--active {
        background: var(--v-ap-blue-base) !important;
        color: #fff !important;
      }
    }
  }

  .section-hidden {
    transform: rotate(-90deg);
  }
}
</style>
