<template>
  <div>
    <div class="text-h5 font-weight-black mb-3">
      Tell us how to identify duplicates
    </div>

    <div class="text-body-2 mb-4">
      <v-icon small class="mr-1" color="primary">
        $mdi-information-outline
      </v-icon>
      Set up your match criteria starting with the strictest first.
    </div>

    <ApLoading v-if="isLoading" class="mt-10" text="Loading metadata ...." />

    <RulesStatus
      v-else-if="matchingCriterias.length === 0"
      class="my-10"
      status-icon="$mdi-database-off-outline"
      title="No Match Criterias defined yet"
      description="Set up the Match Criterias before going to the next step."
      button-icon="$mdi-plus"
      button-label="Add you first Match Criteria"
      @click="onAddNew"
    />

    <template v-else>
      <MatchingCriteriaIdentifyDuplicatesList
        :items="matchingCriterias"
        @edit="onEdit"
        @delete="onDelete"
      />

      <div class="d-flex justify-end">
        <v-btn
          text
          block
          outlined
          small
          class="text-none font-weight-regular"
          @click="onAddNew"
        >
          <v-icon left>$mdi-plus</v-icon>
          {{
            matchingCriterias && matchingCriterias.length > 0
              ? 'New Match Criteria'
              : 'Insert your first Match Criteria'
          }}
        </v-btn>
      </div>
    </template>

    <MatchingCriteriaIdentifyDuplicatesForm
      v-if="isConditionsDialogVisible"
      :operators="operators"
      :fields="entities"
      :conditions="conditions"
      :title="matchingCriteriaFormTitle"
      :is-submitting="isSubmitting"
      @save="onSave"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import ApLoading from '@/components/common/ApLoading'
import MatchingCriteriaIdentifyDuplicatesForm from './MatchingCriteriaIdentifyDuplicatesForm'
import MatchingCriteriaIdentifyDuplicatesList from './MatchingCriteriaIdentifyDuplicatesList'
import RulesStatus from './RulesStatus'
import { mapActions, mapState } from 'vuex'
import { RULE_TYPES } from '@/api/business-rules/rules'
import Vue from 'vue'
import { cloneDeep, isEqual } from 'lodash-es'

export default {
  components: {
    ApLoading,
    MatchingCriteriaIdentifyDuplicatesForm,
    MatchingCriteriaIdentifyDuplicatesList,
    RulesStatus,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isConditionsDialogVisible: false,
      isSubmitting: false,

      originalMatchingCriterias: [],
      matchingCriterias: [],
      matchingCriteriasSelectedIndex: null,

      isLoading: false,
    }
  },
  computed: {
    ...mapState('business-rules', {
      isTriggeringSave: (state) =>
        state.directorRule.rules.MatchCriteria.isTriggeringSave,
      rulesMetadata: (state) => state.rulesMetadata,
      multiMapRule: (state) => state.directorRule.rules.MultiMap,
    }),
    operators() {
      return this.rulesMetadata?.MatchCriteria?.[
        this.multiMapRule.primaryEntity
      ]?.operators
    },
    entities() {
      return this.rulesMetadata?.MatchCriteria?.[
        this.multiMapRule.primaryEntity
      ]?.entities
    },
    conditions() {
      const conditions =
        this.matchingCriterias[this.matchingCriteriasSelectedIndex]?.conditions

      if (this.matchingCriteriasSelectedIndex === null) {
        return undefined
      }

      // Force id to avoid problems when deleting conditions
      return conditions.map((el, index) => {
        return {
          id: index,
          ...el,
        }
      })
    },
    matchingCriteriaFormTitle() {
      return this.matchingCriteriasSelectedIndex !== null
        ? `Matching Criteria ${this.matchingCriteriasSelectedIndex + 1}`
        : `Matching Criteria ${this.matchingCriterias.length + 1}`
    },
  },
  watch: {
    async isTriggeringSave(value) {
      if (value) {
        try {
          if (
            !isEqual(this.originalMatchingCriterias, this.matchingCriterias)
          ) {
            if (this.rule.id) {
              await this.updateMatchCriteriaRule({
                rule: this.rule,
                criterias: this.matchingCriterias,
              })
            } else {
              await this.createMatchCriteriaRule({
                directorRuleId: this.$route.params.directorRuleId,
                rule: this.rule,
                criterias: this.matchingCriterias,
              })
            }
          }

          this.$emit('saved')
        } finally {
          this.resetTriggerSaveCurrentRule(this.rule.type)
        }
      }
    },
  },
  created() {
    this.matchingCriterias = this.rule.rule.config.map((item) => item.config)
    this.originalMatchingCriterias = cloneDeep(this.matchingCriterias)

    this.getMetadata()
  },
  methods: {
    ...mapActions('business-rules', [
      'resetTriggerSaveCurrentRule',
      'updateMatchCriteriaRule',
      'getRuleEntityMetadata',
      'createMatchCriteriaRule',
    ]),
    async getMetadata() {
      if (
        !this.rulesMetadata?.MatchCriteria?.[this.multiMapRule.primaryEntity]
      ) {
        this.isLoading = true
        await this.getRuleEntityMetadata({
          entities: [this.multiMapRule.primaryEntity],
          ruleType: RULE_TYPES.matchCriteria,
        })
        this.isLoading = false
      }
    },
    onDelete(index) {
      this.matchingCriterias.splice(index, 1)
    },
    onEdit(matchingCriteriaIndex) {
      this.matchingCriteriasSelectedIndex = matchingCriteriaIndex
      this.isConditionsDialogVisible = true
    },
    onAddNew() {
      this.matchingCriteriasSelectedIndex = null
      this.isConditionsDialogVisible = true
    },
    async onSave(conditions) {
      this.isSubmitting = true

      const conditionsWithoutTemporayId = conditions.map((condition) => {
        delete condition.id

        return {
          ...condition,
        }
      })

      if (this.matchingCriteriasSelectedIndex === null) {
        this.matchingCriterias = [
          ...this.matchingCriterias,
          {
            confidence: null,
            conditions: conditionsWithoutTemporayId,
          },
        ]
      } else {
        Vue.set(this.matchingCriterias, this.matchingCriteriasSelectedIndex, {
          confidence:
            this.matchingCriterias[this.matchingCriteriasSelectedIndex]
              ?.confidence,
          conditions: conditionsWithoutTemporayId,
        })
      }

      this.isConditionsDialogVisible = false
      this.matchingCriteriasSelectedIndex = null
      this.isSubmitting = false
    },
    onCancel() {
      this.isConditionsDialogVisible = false
      this.matchingCriteriasSelectedIndex = null
    },
  },
}
</script>
