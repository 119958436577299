<template>
  <RulesStatus
    class="my-10"
    status-icon="$mdi-alert-circle-outline"
    title="CleanData Server Message"
    description="CleanData is hard at work analyzing data to identify and fix data quality issues."
    button-icon="$mdi-refresh"
    button-label="Try again!"
    @click="$emit('click')"
  />
</template>

<script>
import RulesStatus from './RulesStatus.vue'
export default {
  components: {
    RulesStatus,
  },
}
</script>
