<template>
  <ApDialog
    :value="true"
    :title="title"
    :actions="actions"
    max-width="960"
    @cancel="$emit('cancel')"
    @save="onSave"
  >
    <template #content>
      <div class="px-6 ap-black--text">
        <v-form ref="form">
          <div v-for="(condition, index) of conditionsTemp" :key="condition.id">
            <MatchingCriteriaIdentifyDuplicatesFormCondition
              :value="condition"
              :fields="fields"
              :operators="operators"
              :is-delete-visible="conditionsTemp.length > 1"
              @input="onConditionChange(index, $event)"
              @delete="onDelete(index, condition)"
            />

            <div
              v-if="index < conditionsTemp.length - 1"
              class="text-center my-3 font-weight-medium"
            >
              And
            </div>
          </div>

          <div class="d-flex justify-end my-2">
            <v-btn
              text
              small
              class="ap-blue--text text-body-2 text-none font-weight-regular"
              @click="addNewField"
            >
              <v-icon left> $mdi-plus </v-icon>
              New Field
            </v-btn>
          </div>
        </v-form>
      </div>
    </template>
  </ApDialog>
</template>

<script>
import MatchingCriteriaIdentifyDuplicatesFormCondition from './MatchingCriteriaIdentifyDuplicatesFormCondition'
import ApDialog from '@/components/common/ApDialog'
import Vue from 'vue'

const defaultCriteriaStructure = {
  algo: null,
  field: '',
  match_field: null,
  case_sensitive: false,
  value: null,
}

export default {
  components: {
    MatchingCriteriaIdentifyDuplicatesFormCondition,
    ApDialog,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => {},
    },
    operators: {
      type: Array,
      default: () => [],
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    conditions: {
      type: Array,
      default: () => [{ ...defaultCriteriaStructure }],
    },
  },
  data() {
    return {
      conditionsTemp: [],
    }
  },
  computed: {
    actions() {
      return [
        {
          event: 'save',
          label: 'Done',
          color: 'primary',
          loading: this.isSubmitting,
        },
      ]
    },
  },
  async created() {
    this.conditionsTemp = [...this.conditions]
  },
  methods: {
    onConditionChange(index, value) {
      Vue.set(this.conditionsTemp, index, value)
    },
    addNewField() {
      this.conditionsTemp.push({
        ...defaultCriteriaStructure,
      })
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.$emit(
          'save',
          this.conditionsTemp.map((el) => {
            return {
              ...el,
              match_field: el.field,
            }
          })
        )
      }
    },
    onDelete(index) {
      this.conditionsTemp.splice(index, 1)
    },
  },
}
</script>
